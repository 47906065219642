import React, { useState } from 'react';
import ImagePreviewContent from './ImagePreviewContent';
import ImageLibraryContent from './ImageLibraryContent';
import Dialog from '../dialogs/Dialog';
import { Image } from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';

const mediaLayoutKey = 'mediaLayout';

type ImageSelectionDialogProps = {
  isOpen: boolean;
  images: Image[];
  onSelect: (key: string) => void;
  onClose: () => void;
};

export default function ImageSelectionDialog({
  isOpen,
  images,
  onSelect,
  onClose,
}: ImageSelectionDialogProps) {
  const [focusedKey, setFocusedKey] = useState<string>();
  const [previewedKey, setPreviewedKey] = useState<string>();
  const [mediaLayout, setMediaLayout] = useState<string>(
    localStorage.getItem(mediaLayoutKey) ?? 'grid',
  );
  const previewedImage = images.find((image) => image.key === previewedKey);

  function closeAndReset() {
    onClose();
    setFocusedKey(undefined);
    setPreviewedKey(undefined);
  }

  function updateMediaLayout(b: boolean) {
    const value = b ? 'list' : 'grid';
    setMediaLayout(value);
    localStorage.setItem(mediaLayoutKey, value);
  }

  const title = previewedImage
    ? strings.dialogs.imagePreview
    : strings.dialogs.mediaLibrary;
  const subtitle = previewedImage
    ? undefined
    : strings.dialogs.selectAnImageToUpload;

  const isList = mediaLayout === 'list';

  // Note: Use a common `Dialog` wrapper to avoid transition animations
  return (
    <Dialog
      title={title}
      subtitle={subtitle}
      height="861px"
      isOpen={isOpen}
      onClose={closeAndReset}
      hasToggle={true}
      toggleValue={isList}
      onToggleChange={updateMediaLayout}
    >
      {previewedImage ? (
        <ImagePreviewContent
          image={previewedImage}
          onSelect={() => {
            if (previewedKey !== undefined) {
              onSelect(previewedKey);
              closeAndReset();
            }
          }}
          onClose={() => setPreviewedKey(undefined)}
        />
      ) : (
        <ImageLibraryContent
          images={images}
          renderAsList={isList}
          focusedKey={focusedKey}
          onFocus={setFocusedKey}
          onPreview={setPreviewedKey}
          onSelect={() => {
            if (focusedKey !== undefined) {
              onSelect(focusedKey);
              closeAndReset();
            }
          }}
          onClose={closeAndReset}
        />
      )}
    </Dialog>
  );
}
