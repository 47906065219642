import { SimpleGrid, List } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import ImageLibraryTile from './ImageLibraryTile';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { DialogBody, DialogButtons } from '../dialogs/Dialog';
import { Image } from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';
import { ImageLibraryListItem } from './ImageLibraryListItem';

type ImageLibraryContentProps = {
  images: Image[];
  focusedKey: string | undefined;
  renderAsList: boolean;
  onFocus: (key: string | undefined) => void;
  onPreview: (key: string) => void;
  onSelect: () => void;
  onClose: () => void;
};

export default function ImageLibraryContent({
  images,
  focusedKey,
  renderAsList,
  onFocus,
  onPreview,
  onSelect,
  onClose,
}: ImageLibraryContentProps) {
  return (
    <Fragment>
      <DialogBody>
        {renderAsList ? (
          <List>
            {images.map((image) => (
              <ImageLibraryListItem
                key={image.key}
                image={image}
                isFocused={focusedKey === image.key}
                onFocus={() => onFocus(image.key)}
                onUnfocus={() => onFocus(undefined)}
                onPreview={() => onPreview(image.key)}
              />
            ))}
          </List>
        ) : (
          <SimpleGrid columns={4} spacing="40px">
            {images.map((image) => (
              <ImageLibraryTile
                key={image.key}
                image={image}
                isFocused={focusedKey === image.key}
                onFocus={() => onFocus(image.key)}
                onUnfocus={() => onFocus(undefined)}
                onPreview={() => onPreview(image.key)}
              />
            ))}
          </SimpleGrid>
        )}
      </DialogBody>

      <DialogButtons>
        <SecondaryButton label={strings.common.cancel} onClick={onClose} />
        <PrimaryButton
          label={strings.common.select}
          isDisabled={focusedKey === undefined}
          onClick={onSelect}
        />
      </DialogButtons>
    </Fragment>
  );
}
