import React from 'react';
import { Image } from '../../../gql/gqlRequests';
import {
  Box,
  Center,
  Button,
  Image as ChakraImage,
  Text,
} from '@chakra-ui/react';
import { getFileSizeText } from '../../../utils/file';

type ImageLibraryListItemProps = {
  image: Image;
  isFocused: boolean;
  onFocus: () => void;
  onUnfocus: () => void;
  onPreview: () => void;
};

export const ImageLibraryListItem = ({
  image,
  isFocused,
  onFocus,
  onPreview,
  onUnfocus,
}: ImageLibraryListItemProps) => {
  return (
    <Box
      onClick={isFocused ? () => onUnfocus() : () => onFocus()}
      key={image.key}
      display="flex"
      cursor="pointer"
      flexDirection="row"
      borderRadius="10px"
      border="2px solid"
      padding="10px"
      borderColor={isFocused ? 'secondary.secondaryBlue.500' : 'transparent'}
      gap={'30px'}
      marginBottom="10px"
    >
      <Center
        position={'relative'}
        height="190px"
        w="190px"
        backgroundColor="neutrals.brandGrey.400"
        borderRadius="10px"
      >
        <ChakraImage
          src={image.url}
          alt={image.filename}
          layerStyle="imageLibraryTile"
        />
      </Center>
      <Box display="flex" flexDirection="row" flex="1">
        <Box display="flex" flexDirection="column" flex="1" minW={0}>
          <Text
            fontWeight="bold"
            noOfLines={3}
            wordBreak={'break-word'}
            fontSize={'lg'}
          >
            {image.filename}
          </Text>

          <Text>{getFileSizeText(image.size)}</Text>
        </Box>
        <Box alignSelf="center" justifySelf="flex-end">
          <Button onClick={onPreview}>Preview</Button>
        </Box>
      </Box>
    </Box>
  );
};
