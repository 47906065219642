import React, { useContext, useMemo } from 'react';
import Page from '../../common/Page';
import { createColumnHelper } from '@tanstack/react-table';
import {
  AppsStatusesQuery,
  AppsStatusesQueryVariables,
  Role,
  Status,
} from '../../../gql/gqlRequests';
import StandardTable, {
  TablePagination,
} from '../../common/tables/StandardTable';
import { EnvironmentContext, UserContext } from '../../../contexts';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { appsStatusesRequest } from '../../../support/apps';
import { useQuery } from '@tanstack/react-query';
import { RequestError } from '../../../types';
import { displayEnum } from '../../../utils';
import { parsedRequestError } from '../../../utils/errors';
import Link from '../../common/Link';
import { routes } from '../../../types/routes';
import moment from 'moment';
import { strings } from '../../../utils/strings';
import { Text } from '@chakra-ui/react';

const DATE_FORMAT = 'DD/MM/yyyy HH:mm';

type StatusRow = {
  id: string;
  account: string;
  app: string;
  appId: string;
  environment: string;
  lastSuccessfulDate?: Date;
  status: Status;
  date: Date;
  createdAt: Date;
  platforms: string[];
};

const columnHelper = createColumnHelper<StatusRow>();

export default function PublishingStatus() {
  const { environment } = useContext(EnvironmentContext);
  const { loggedInUser } = useContext(UserContext);

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<TablePagination>({ pageIndex: 0, pageSize: 20 });

  const appsStatusesQueryFn = useAuthRequest<
    AppsStatusesQueryVariables,
    AppsStatusesQuery
  >(appsStatusesRequest);

  const appsStatusesQuery = useQuery<AppsStatusesQuery, RequestError>({
    queryKey: ['statuses', environment, pageIndex],
    queryFn: () =>
      appsStatusesQueryFn({ environment, page: pageIndex + 1, size: pageSize }),
  });

  const columns = useMemo(() => {
    const columns = [
      columnHelper.accessor('id', {}),
      columnHelper.accessor('createdAt', {}),
      columnHelper.accessor('app', {
        header: 'App',
        cell: (info) => (
          <Link
            label={info.getValue()}
            to={routes.appDetail({ appId: info.row.original.appId })}
          />
        ),
      }),
      columnHelper.accessor('environment', {
        header: 'Environment',
        cell: (info) => displayEnum(info.getValue()),
      }),
      columnHelper.accessor('platforms', {
        header: 'Platforms',
        cell: (info) => (
          <Text w={150} overflow={'hidden'} textOverflow={'ellipsis'}>
            {info.getValue().join(', ') || '-'}
          </Text>
        ),
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: (info) => displayEnum(info.getValue()),
      }),
      columnHelper.accessor('date', {
        header: 'Date',
        cell: (info) => (
          <Link
            label={moment(info.getValue()).format(DATE_FORMAT)}
            to={info.row.original.id}
          />
        ),
      }),
      columnHelper.accessor('lastSuccessfulDate', {
        header: 'Last Successful Date',
        cell: (info) => {
          const value = info.getValue();
          return !value ? '-' : moment(value).format(DATE_FORMAT);
        },
      }),
    ];
    return loggedInUser.role === Role.SystemAdmin
      ? [columnHelper.accessor('account', { header: 'Account' }), ...columns]
      : columns;
  }, [loggedInUser]);

  const appsStatusesData = useMemo(
    () =>
      (appsStatusesQuery.data?.getAllAppsStatuses.items ?? []).map(
        ({
          account,
          app,
          createdAt,
          environment,
          id,
          status,
          lastSuccessfulDate,
          appId,
          platforms,
        }) => ({
          id,
          account,
          app,
          appId,
          environment,
          platforms,
          status,
          date: createdAt,
          createdAt,
          lastSuccessfulDate,
        }),
      ),
    [appsStatusesQuery.data],
  );

  if (appsStatusesQuery.isError)
    throw parsedRequestError(appsStatusesQuery.error);

  return (
    <Page
      title="App Status"
      withBreadcrumbs
      withEnvironmentBar
      isLoading={appsStatusesQuery.isLoading}
    >
      <StandardTable
        title={strings.apps.allApps}
        isLoading={appsStatusesQuery.isLoading}
        data={appsStatusesData}
        columns={columns}
        boldColumn="app"
        totalRows={
          appsStatusesQuery.data?.getAllAppsStatuses?.total ?? undefined
        }
        pagination={{
          pageIndex,
          pageSize,
        }}
        updatePagination={setPagination}
      />
    </Page>
  );
}
